import React, {Component} from "react";
import {Button, Col, Form, Input, message, Row, Select} from "antd";
import {defaultTo, map} from "lodash";
import moment from "moment";
import {firestore, setDocument} from "../../../firebase";

export default Form.create()(
    class user extends Component {

        state = {
            id: this.props.match.params.userId,
            name: "",
            email: "",
            password: "",
            createAt: "",
            companyId: ""
        };

        componentDidMount = async () => await this.fetchUsers();

        fetchUsers = async () => {
            const currentUser = this.props.users.find(user => user.id === this.state.id);

            if (this.state.id === "new") return await this.userToState({});

            return this.userToState(currentUser);
        };

        userToState = async (user) => {
            const userRef = await firestore.collection("users");
            let userId = await userRef.doc().id;

            this.setState({
                id: defaultTo(user.id, userId),
                companyId: defaultTo(user.companyId, ""),
                name: defaultTo(user.name, ""),
                email: defaultTo(user.email, ""),
                password: defaultTo(user.password, ""),
                createAt: defaultTo(user.createAt, moment().toDate())
            });
        };

        stateToUser = () => ({
            id: this.state.id,
            companyId: this.state.companyId,
            name: this.state.name,
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            createAt: this.state.createAt
        });

        saveUser = (event) => {
            event.preventDefault();
            this.props.form.validateFields((err) => {
                if (!err) {
                    this.props.match.params.userId === "new" ? this.addUser() : this.updateUser()
                }
            });
        };

        addUser = () => {
            const newUser = this.stateToUser();

            setDocument("users", newUser.id, newUser);

            message.success("User saved");

            return this.props.history.push("/admin/users");
        };

        updateUser = () => {
            const updateUser = this.stateToUser();

            setDocument("users", updateUser.id, updateUser);

            message.success("User updated");

            return this.props.history.push("/admin/users");
        };

        emailExists = (rule, value, callback) => {
            if (this.props.users.find(user => user.email === value && user.id !== this.state.id)) callback("Email already exists.");
            else callback();
        };

        render() {
            const {getFieldDecorator} = this.props.form;

            return (
                <div>
                    <Row gutter={24}>
                        <Col sm={24}
                             md={24}
                             lg={24}
                             xxl={24}>
                            <div>
                                <Form onSubmit={this.saveUser}
                                      autoComplete="off">
                                    <div>
                                        <Form.Item label="Email: ">
                                            {
                                                getFieldDecorator("email", {
                                                    initialValue: this.state.email,
                                                    rules: [{validator: this.emailExists},
                                                        {required: true, message: "Email is required."}],
                                                })(
                                                    <Input placeholder="Email"
                                                           onChange={event => this.setState({"email": event.target.value})}/>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item label="Password: ">
                                            {
                                                getFieldDecorator("password", {
                                                    initialValue: this.state.password,
                                                    rules: [{required: true, message: "Password is required."}, {
                                                        min: 6,
                                                        message: "Min length"
                                                    }],
                                                })(
                                                    <Input.Password placeholder="password"
                                                                    type="password"
                                                                    onChange={event => this.setState({"password": event.target.value})}/>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item label="Company: ">
                                            {
                                                getFieldDecorator("company", {
                                                    initialValue: this.state.companyId,
                                                    rules: [{required: true, message: "Name is required."}],
                                                })(
                                                    <Select placeholder="Select a company"
                                                            style={{width: "100%"}}
                                                            onChange={value => this.setState({"companyId": value})}>
                                                        {
                                                            map(this.props.companies, status => (
                                                                <Select.Option key={status.id}
                                                                               value={status.id}>
                                                                    {status.name}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item label="Name: ">
                                            {
                                                getFieldDecorator("name", {
                                                    initialValue: this.state.name,
                                                    rules: [{required: true, message: "Name is required."}],
                                                })(
                                                    <Input placeholder="Name"
                                                           onChange={event => this.setState({"name": event.target.value})}/>
                                                )
                                            }
                                        </Form.Item>
                                        <div>
                                            <Row gutter={16}
                                                 type="flex"
                                                 justify="end"
                                                 style={{width: "100%", margin: "0px"}}>
                                                <Col className="gutter-row">
                                                    <Button type="secondary"
                                                            onClick={() => this.props.history.push("/admin/users")}>
                                                        CANCEL
                                                    </Button>
                                                </Col>
                                                <Col className="gutter-row">
                                                    <Button type="primary"
                                                            htmlType="submit">
                                                        SAVE
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
)