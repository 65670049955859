import * as ReactGA from "react-ga";
import {defaultTo} from "lodash";
import uuidv1 from "uuid/v1";

export const initializeClientId = (callback) => {
    let clientId;

    if (localStorage.getItem("clientId")) {
        clientId = localStorage.getItem("clientId");
    } else {
        clientId = uuidv1();
        localStorage.setItem("clientId", clientId);
    }

    return callback(clientId);
};

export const initializeReactGA = (trackingId) => {
    ReactGA.initialize(trackingId);
};

export const gaEvent = (category, action, label, value) =>
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: +defaultTo(value, null),
    });

export const gaError = (category, action, label) => gaEvent(category, action, defaultTo(label, null), null);

export const gaPurchase = (category, action, value) => gaEvent(category, action, null, defaultTo(value, null));

export const gaFilter = (category, action) => gaEvent(category, action, null, null);

export const gaSettings = (category, action, label) => gaEvent(category, action, label, null);

export const gaSort = (category, action) => gaEvent(category, action, null, null);


