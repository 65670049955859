import React, {Component} from "react";
import {Button, Icon, List, Modal, Row} from "antd";
import {deleteDocument} from "../../../firebase";
import {Link} from "react-router-dom";
import titleize from "titleize";
import {orderBy} from "lodash";
import moment from "moment";

export default class User extends Component {

    wrappedLink = (path, component) => {
        return (
            <Link to={path}>
                {component}
            </Link>
        )
    };

    deleteUser = (user) => {
        Modal.confirm({
            title: "Are you sure delete?",
            content: "All data with connection to this item deleted.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => deleteDocument("users", user.id),
        })
    };

    render() {
        return (
            <div>
                <div>
                    <span style={{fontSize: "25px"}}>
                    <Icon type="team"/>Users</span>
                </div>
                <div style={{textAlign: "center", marginTop: "25px", marginBottom: '15px'}}>
                    <Button type="primary"
                            icon="plus-circle"
                            className="square-button"
                            onClick={() => this.props.history.push("/admin/users/new")}>
                        Add User
                    </Button>
                </div>
                <Row>
                    <List itemLayout="vertical"
                          size="large"
                          dataSource={orderBy(this.props.users, user => titleize(user.name), ['name'], ['asc'])}
                          footer={
                              <div>
                                  <hr/>
                              </div>}
                          renderItem={user => (
                              <List.Item style={{cursor: "pointer", display: "flex"}}
                                         actions={[
                                             <div onClick={() => this.deleteUser(user)}>
                                                 <Icon style={{color: '#fe008f', fontSize: '24px',}}
                                                       type="delete"/>
                                             </div>
                                         ]}>
                                  {
                                      this.wrappedLink(`/admin/users/${user.id}`,
                                          <div>
                                              <span className="font-user-list-secondary">
                                                  {moment(user.createAt.seconds * 1000).format("ddd DD MMMM YYYY")}
                                              </span>
                                              <h3 style={{margin: '0px', color: "#40a9ff"}}>
                                                  {titleize(user.name)}
                                              </h3>
                                              <span className="font-user-list-tertiary">
                                                {user.email}
                                              </span>
                                              <br/>
                                              <span style={{fontWeight: "500"}}
                                                    className="font-user-list-tertiary">
                                                  {this.props.companies && this.props.companies.find(company => company.id === user.companyId).name}
                                              </span>
                                          </div>
                                      )
                                  }
                              </List.Item>
                          )}/>
                </Row>
            </div>
        );
    }
}