import React, {Component} from 'react';
import {Avatar, Button, Card, Form, Input} from 'antd';
import {doSignInWithEmailAndPassword} from "../../firebase/auth";
import firebaseAuthenticationError from "../../firebase/firebaseAuthenticationErrors";
import withAuthorization from "../../session/withAuthorization";
import {trim} from "lodash";
import {prefixIcon} from "../../utils";
import iconAvatar from "../../res/images/icon.png";

const admin = Form.create()(
    class Admin extends Component {

        state = {
            email: "",
            password: "",
            loginStatus: false,
        };

        login = (event) => {
            event.preventDefault();

            this.setState({
                email: trim(this.state.email),
                loginStatus: true
            }, () => {
                if (!this.state.email && !this.state.password) {
                    this.setState({
                        errorMessage: "Email and password required.",
                        loginStatus: false
                    })
                } else if (!this.state.email) {
                    this.setState({
                        errorMessage: "Email required.",
                        loginStatus: false
                    })
                } else if (!this.state.password) {
                    this.setState({
                        errorMessage: "Password required.",
                        loginStatus: false
                    })
                } else {
                    doSignInWithEmailAndPassword(this.state.email.toLowerCase(), this.state.password,
                        null,
                        error => {
                            this.setState({
                                errorMessage: firebaseAuthenticationError[error.code],
                                loginStatus: false
                            })
                        });
                }
            });
        };

        render() {
            const {errorMessage, loginStatus} = this.state;

            return (
                <div className="login-content">
                    <Card className="login-card">
                        <Form onSubmit={this.login}
                              className="login-form">
                            <h1 className="title">Track me</h1>
                            <div style={{textAlign: "center"}}>
                                <Form.Item style={{marginBottom: "20px"}}>
                                    <Avatar size={100}
                                            shape="square"
                                            src={iconAvatar}/>
                                </Form.Item>
                            </div>
                            <h3>{errorMessage}</h3>
                            <Form.Item validateStatus={errorMessage ? "error" : ""}
                                       required>
                                <Input prefix={prefixIcon("user")}
                                       type="text"
                                       name="email"
                                       value={this.state.email}
                                       onChange={(event) => this.setState({
                                           email: event.target.value,
                                           errorMessage: null
                                       })}
                                       autoComplete="off"
                                       placeholder="Enter your email"/>
                            </Form.Item>
                            <Form.Item validateStatus={errorMessage ? "error" : ""}
                                       required>
                                <Input.Password prefix={prefixIcon("lock")}
                                                id="password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={(event) => this.setState({
                                                    password: event.target.value,
                                                    errorMessage: null
                                                })}
                                                autoComplete="off"
                                                placeholder="Enter your Password"/>
                            </Form.Item>
                            <Button type="primary"
                                    block={true}
                                    htmlType="submit"
                                    loading={loginStatus}
                                    disabled={errorMessage}>LOGIN</Button>
                        </Form>
                    </Card>
                </div>
            )
        }
    });

const condition = authUser => !authUser;
export default withAuthorization(condition, "/admin/users")(admin);