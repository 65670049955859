import React, {Component} from "react";
import {GoogleApiWrapper, InfoWindow, Map, Marker, Polyline} from "google-maps-react";
import {Button, Dropdown, Icon, Menu} from "antd";
import {defaultTo, get, inRange} from "lodash";
import {firestore} from "../../firebase"
import moment from "moment";
import {
    busStops,
    i18n,
    iconAirplane,
    iconBus,
    iconRoute,
    logo,
    routeCoordinates,
    snapshotToArray,
    timeTables
} from "../../utils";

const coordinateAirport = {lat: -12.023696, lng: -77.112015};

class MapsByCompany extends Component {

    bufferState = {
        direction: "miraflores-to-airport",
    };

    state = {
        zoom: null,
        centerMap: null,
        language: "es",
        showingInfoMarker: false,
        activeMarker: {},
        detailMarker: "",
    };

    componentDidMount = async () => {
        const schedule = await this.fetchSchedule();
        this.bufferState.time = this.formatDate(schedule.data().updateAt);

        this.fetchA();
        this.fetchB();
        this.fetchC();
        this.fetchD();
        this.fetchScheduleOnSnapshot();
    };

    fetchA = () => {
        this.unsubscribeCoordinatesA && this.unsubscribeCoordinatesA();
        this.unsubscribeCoordinatesA = firestore
            .collection("coordinates")
            .where("user.email", "==", "a@ael.com")
            .orderBy("createAt", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                this.bufferState.coordinatesA = snapshotToArray(snapshot)[0];

                this.bufferState["activeBusA"] = this.activeBus(snapshotToArray(snapshot)[0].createAt);

                this.showBus("a");
            });
    };

    fetchB = () => {
        this.unsubscribeCoordinatesB && this.unsubscribeCoordinatesB();
        this.unsubscribeCoordinatesB = firestore
            .collection("coordinates")
            .where("user.email", "==", "b@ael.com")
            .orderBy("createAt", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                this.bufferState.coordinatesB = snapshotToArray(snapshot)[0];

                this.bufferState["activeBusB"] = this.activeBus(snapshotToArray(snapshot)[0].createAt);

                this.showBus("b");
            });
    };

    fetchC = () => {
        this.unsubscribeCoordinatesC && this.unsubscribeCoordinatesC();
        this.unsubscribeCoordinatesC = firestore
            .collection("coordinates")
            .where("user.email", "==", "c@ael.com")
            .orderBy("createAt", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                this.bufferState.coordinatesC = snapshotToArray(snapshot)[0];

                this.bufferState["activeBusC"] = this.activeBus(snapshotToArray(snapshot)[0].createAt);

                this.showBus("c");
            });
    };

    fetchD = () => {
        this.unsubscribeCoordinatesD && this.unsubscribeCoordinatesD();
        this.unsubscribeCoordinatesD = firestore
            .collection("coordinates")
            .where("user.email", "==", "d@ael.com")
            .orderBy("createAt", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                this.bufferState.coordinatesD = snapshotToArray(snapshot)[0];

                this.bufferState["activeBusD"] = this.activeBus(snapshotToArray(snapshot)[0].createAt);

                this.showBus("d");
            });
    };

    fetchScheduleOnSnapshot = () => {
        this.unsubscribeCreateAt && this.unsubscribeCreateAt();
        this.unsubscribeCreateAt = firestore
            .collection("schedules")
            .doc("default")
            .onSnapshot(snapshot => {
                const time = snapshot.data();

                this.bufferState.time = this.formatDate(time.updateAt);

                this.setState({...this.bufferState});
            });
    };

    fetchSchedule = async () =>
        firestore
            .collection("schedules")
            .doc("default")
            .get();

    formatDate = time => moment(time.toDate()).format("HH.mm");

    isRouteInRange = routes => routes.some(route => inRange(this.bufferState.time, route.departureTime, route.arrivalTime));

    busStops = () => busStops.filter(busStop => busStop.direction === this.bufferState.direction).flatMap(busStop => busStop.stops);

    routesCoordinates = () => routeCoordinates.filter(routeCoordinate => routeCoordinate.direction === this.bufferState.direction).flatMap(routeCoordinate => routeCoordinate.routes);

    showBus = bus => {
        const showBus_ = timeTables.some(timeTable => timeTable.direction === this.bufferState.direction && timeTable.bus === bus && this.isRouteInRange(timeTable.routes));

        this.bufferState["show" + bus.toUpperCase()] = showBus_;
    };

    activeBus = lastDateBus => inRange(this.formatDate(lastDateBus), parseFloat(this.bufferState.time), (parseFloat(this.bufferState.time) + 1));

    language = () => i18n.find(language => language.languageCode === this.state.language);

    showMarker = (detail, marker) =>
        this.setState({
            detailMarker: detail.name,
            activeMarker: marker,
            showingInfoMarker: true
        });

    hideMarker = () => {
        if (this.state.showingInfoMarker) {
            this.setState({
                showingInfoMarker: false,
                activeMarker: null
            })
        }
    };

    render() {

        const menu = (
            <Menu>
                {
                    i18n.map((language, index) =>
                        <Menu.Item key={index}
                                   style={{textAlign: "right"}}>
                            <a onClick={() => this.setState({language: language.languageCode})}>
                                {language.languageName} {language.languageFlag}
                            </a>
                        </Menu.Item>
                    )
                }
            </Menu>
        );

        return (
            <div className="container-maps">
                <div className="container-title">
                    <div className="content-language">
                        <div className="item-logo">
                            <a href="https://www.airportexpresslima.com/"
                               rel="noopener noreferrer"
                               target="_blank">
                                <img alt="airportExpress"
                                     className="logo"
                                     src={logo}/>
                            </a>
                        </div>
                        <div className="item-language">
                            <Dropdown overlay={menu}
                                      trigger={['click']}>
                                <a className="ant-dropdown-link"
                                   href="#">
                                    {this.language().languageFlag} <Icon type="down"/>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="content-title-map">
                        <h3><b>{this.language().title}</b>
                        </h3>
                        <p>{this.language().subtitle}</p>
                    </div>
                    <div className="content-button">
                        <Button className={this.state.centerMap === null ? "button-active" : ""}
                                href="#map"
                                onClick={() =>
                                    this.setState({
                                        centerMap: {lat: -12.125558, lng: -77.030837},
                                        zoom: 15
                                    })}>
                            MIRAFLORES
                        </Button>
                        <Button href="#map"
                                onClick={() =>
                                    this.setState({
                                        centerMap: {lat: -12.103177, lng: -77.035503},
                                        zoom: 15
                                    })}>
                            SAN ISIDRO
                        </Button>
                    </div>
                    <div className="content-title-map">
                        <h3><b>{this.language().messageTitle}</b></h3>
                        <p>{this.language().message}</p>
                        <p>{this.language().note} <b>{this.language().noteBold}</b></p>
                    </div>
                </div>
                <div id="map"
                     className="content-maps">
                    <Map google={this.props.google}
                         onClick={this.hideMarker}
                         initialCenter={{lat: -12.125558, lng: -77.030837}}
                         center={this.state.centerMap}
                         zoom={defaultTo(this.state.zoom, 15)}>
                        {
                            this.busStops().map((busStop, index) =>
                                <Marker className="map"
                                        onClick={this.showMarker}
                                        name={`${busStop.name} - ${busStop.direction}`}
                                        key={index}
                                        position={{lat: busStop.lat, lng: busStop.lng}}
                                        icon={{url: iconRoute}}
                                />
                            )
                        }
                        {
                            (this.bufferState.showA && this.bufferState.activeBusA) &&
                            <Marker className="map"
                                    position={{
                                        lat: get(this.bufferState, "coordinatesA.latitude", -12.072529),
                                        lng: get(this.bufferState, "coordinatesA.longitude", -77.073767)
                                    }}
                                    icon={{url: iconBus}}
                                    label={{
                                        text: `A`,
                                        fontSize: "17px",
                                        fontWeight: "bold"
                                    }}
                            />
                        }
                        {
                            (this.bufferState.showB && this.bufferState.activeBusB) &&
                            <Marker className="map"
                                    position={{
                                        lat: get(this.bufferState, "coordinatesB.latitude", -12.072529),
                                        lng: get(this.bufferState, "coordinatesB.longitude", -77.073767)
                                    }}
                                    icon={{url: iconBus}}
                                    label={{
                                        text: `B`,
                                        fontSize: "17px",
                                        fontWeight: "bold"
                                    }}
                            />
                        }
                        {
                            (this.bufferState.showC && this.bufferState.activeBusC) &&
                            <Marker className="map"
                                    position={{
                                        lat: get(this.bufferState, "coordinatesC.latitude", -12.072529),
                                        lng: get(this.bufferState, "coordinatesC.longitude", -77.073767)
                                    }}
                                    icon={{url: iconBus}}
                                    label={{
                                        text: `C`,
                                        fontSize: "17px",
                                        fontWeight: "bold"
                                    }}
                            />
                        }
                        {
                            (this.bufferState.showD && this.bufferState.activeBusD) &&
                            <Marker className="map"
                                    position={{
                                        lat: get(this.bufferState, "coordinatesD.latitude", -12.072529),
                                        lng: get(this.bufferState, "coordinatesD.longitude", -77.073767)
                                    }}
                                    icon={{url: iconBus}}
                                    label={{
                                        text: `D`,
                                        fontSize: "17px",
                                        fontWeight: "bold"
                                    }}
                            />
                        }
                        <Marker className="map"
                                name="Airport"
                                onClick={this.showMarker}
                                position={coordinateAirport}
                                icon={{url: iconAirplane}}
                        />
                        <Polyline path={this.routesCoordinates()}
                                  geodesic={true}
                                  options={{strokeColor: "black", strokeOpacity: 0.75, strokeWeight: 5}}
                        />
                        <InfoWindow marker={this.state.activeMarker}
                                    visible={this.state.showingInfoMarker}>
                            <h3>{this.state.detailMarker}</h3>
                        </InfoWindow>
                    </Map>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({apiKey: "AIzaSyBn43EfypdU26On7xBQj2g9HM9P9e8rSfk"})(MapsByCompany)