import {Icon, Spin} from "antd";
import React from "react";
import bus from "../res/images/bus.png";
import logoAel from "../res/images/logo-airport.png";
import pin from "../res/images/map-pin.png";
import airplane from "../res/images/airplane-landing.png";

export const iconBus = bus;
export const iconRoute = pin;
export const iconAirplane = airplane;
export const logo = logoAel;
const antIcon = <Icon type="loading"
                      style={{fontSize: 24}}
                      spin/>;
export const spinMap = <Spin style={{marginRight: "10px", marginLeft: "10px"}}
                             indicator={antIcon}/>;