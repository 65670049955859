import React, {Component} from "react";
import {Icon, List, Modal, Tooltip} from "antd";
import Button from "antd/es/button";
import {orderBy} from "lodash";
import {deleteDocument} from "../../../firebase";
import withAuthorization from "../../../session/withAuthorization";

const companies = class Companies extends Component {

    deleteCompany = (company) => {
        Modal.confirm({
            title: "Are you sure delete?",
            content: "All data with connection to this item deleted.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => deleteDocument("companies", company.id),
        })
    };

    render() {
        return (
            <div>
                <div>
                    <span style={{fontSize: "25px"}}>
                    <Icon type="bank"/> Companies</span>
                </div>
                <div style={{textAlign: "center", marginTop: "25px", marginBottom: '15px'}}>
                    <Button type="primary"
                            icon="plus-circle"
                            className="square-button"
                            onClick={() => this.props.history.push("/admin/companies/new")}>
                        Add Company
                    </Button>
                </div>
                <hr/>
                <List itemLayout="vertical"
                      size="large"
                      dataSource={orderBy(this.props.companies, company => company.name, ['name'], ['asc'])}
                      renderItem={company => (
                          <List.Item style={{cursor: "pointer", display: "flex"}}
                                     actions={
                                         [
                                             <Tooltip placement="top"
                                                      title="Delete company">
                                                 <div onClick={() => this.deleteCompany(company)}>
                                                     <Icon style={{color: '#fe008f', fontSize: '24px',}}
                                                           type="delete"/>
                                                 </div>
                                             </Tooltip>
                                         ]}>
                              <div>
                                  <h3 style={{margin: '0px'}}
                                      onClick={() => this.props.history.push(`/admin/companies/${company.id}`)}>
                                      <div style={{color: "#40a9ff"}}>
                                          {company.name}
                                      </div>
                                  </h3>
                              </div>
                          </List.Item>
                      )}>
                </List>
            </div>
        );
    }
};
const condition = authUser => authUser;
export default withAuthorization(condition, "/")(companies);