import React, {Component} from "react";
import {BrowserRouter} from "react-router-dom";
import {fetchCollection} from "./firebase"
import Routes from "./routes"
import withAuthentication from "./session/withAuthentication";
import {spinLoader} from "./utils/loader";

class App extends Component {

    state = {
        images: "https://firebasestorage.googleapis.com/v0/b/blank-project-225314.appspot.com/o/imageProfileDefault%2FimageProfile.jpeg?alt=media&token=2edea679-f0c6-4bcf-b77e-a49c16c2a87e",
        users: [],
        companies: [],
        loadingUsers: true,
        loadingCompanies: true,
    };

    componentDidMount() {
        this.fetchUsers();
        this.fetchCompanies();
    };

    fetchUsers = () => fetchCollection("users", data => this.setState({users: data, loadingUsers: false}));

    fetchCompanies = () => fetchCollection("companies", data =>
        this.setState({
            companies: data,
            loadingCompanies: false
        }));

    render() {
        const {loadingUsers, loadingCompanies} = this.state;

        if (loadingUsers || loadingCompanies) return spinLoader();

        return (
            <BrowserRouter>
                <Routes {...this.state}
                        {...this.props}/>
            </BrowserRouter>
        );
    }
}

export default withAuthentication(App);