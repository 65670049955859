import React, {Component} from "react";
import {fetchCollection} from "../firebase";
import {UpdateVersion} from "../components";
import App from "../App";
import {spinLoader} from "../utils/loader";

export default class CheckVersion extends Component {

    state = {
        version: "0.16",
    };

    componentDidMount() {
        this.fetchVersion();
    }

    fetchVersion = () => {
        this.setState({loadingVersion: true},
            () => fetchCollection("versions", (versions => {
                    if (versions.length === 0) return;

                    this.setState({
                        serverVersion: versions[0].versionNumber,
                        loadingVersion: false,
                    });
                })
            ));
    };

    render() {
        if (this.state.loadingVersion) return spinLoader();

        return (
            (this.state.version !== this.state.serverVersion) ?
                <UpdateVersion className="login-content"/> :
                <App {...this.state}/>
        );
    }
};
