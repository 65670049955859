import React, {Component} from "react";
import {Button, Col, Form, Input, message, Row} from "antd";
import {firestore, setDocument} from "../../../firebase";
import {defaultTo} from "lodash";

const FormItem = Form.Item;

export default Form.create()(
    class Company extends Component {

        state = {
            id: this.props.match.params.companyId,
            name: "",
            companyCode: ""
        };

        componentDidMount = async () => await this.fetchCompanies();

        fetchCompanies = async () => {
            const currentCompany = this.props.companies.find(company => company.id === this.state.id);

            if (this.state.id === "new") return await this.companyToState({});

            return this.companyToState(currentCompany);
        };

        companyToState = async (company) => {
            const companyRef = await firestore.collection("companies");
            let companyId = await companyRef.doc().id;

            this.setState({
                id: defaultTo(company.id, companyId),
                name: defaultTo(company.name, ""),
                companyCode: defaultTo(company.companyCode, "")
            })
        };

        stateToCompany = () => ({
            id: this.state.id,
            name: this.state.name,
            companyCode: this.state.companyCode,
        });

        saveCompany = (event) => {
            event.preventDefault();
            this.props.form.validateFields((err) => {
                if (!err) {
                    this.props.match.params.companyId === "new" ? this.addCompany() : this.updateCompany()
                }
            });
        };

        addCompany = () => {
            const newCompany = this.stateToCompany();

            setDocument("companies", newCompany.id, newCompany);

            message.success("Company saved");

            this.props.history.push("/admin/companies");
        };

        updateCompany = () => {
            const company = this.stateToCompany();

            setDocument("companies", company.id, company);

            message.success("Company Updated");

            this.props.history.push("/admin/companies");
        };

        render() {
            const {getFieldDecorator} = this.props.form;

            return (
                <Form onSubmit={this.saveCompany}>
                    <FormItem label={"Company name"}>
                        {
                            getFieldDecorator("companyName", {
                                initialValue: this.state.name,
                                rules: [{required: true, message: "Company name is required"}],
                            })(
                                <Input placeholder="Company name"
                                       onChange={(event) => this.setState({name: event.target.value})}/>
                            )
                        }
                    </FormItem>
                    <FormItem label={"Company code"}>
                        {
                            getFieldDecorator("companyCode", {
                                initialValue: this.state.companyCode,
                                rules: [{required: true, message: "Company code is required"}],
                            })(
                                <Input placeholder="Company code"
                                       onChange={(event) => this.setState({companyCode: event.target.value})}/>
                            )
                        }
                    </FormItem>
                    <Row gutter={16}
                         type="flex"
                         justify="end"
                         style={{width: "100%", margin: "0px"}}>
                        <Col className="gutter-row">
                            <Button type="secondary"
                                    onClick={() => this.props.history.push("/admin/companies")}>
                                CANCEL
                            </Button>
                        </Col>
                        <Col className="gutter-row">
                            <Button type="primary"
                                    htmlType="submit">
                                SAVE
                            </Button>
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
)