import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const hostName = window.location.hostname;
let apiUrl;

const productionConfig = {
    apiKey: "AIzaSyDoEcLGD2XdtSz1X8Cuhl5fa7U1x46mlOw",
    authDomain: "location-254617.firebaseapp.com",
    databaseURL: "https://location-254617.firebaseio.com",
    projectId: "location-254617",
    storageBucket: "location-254617.appspot.com",
    messagingSenderId: "953484402734",
    appId: "1:953484402734:web:596a291c93572b61967fbf"
};

const devConfig = {
    apiKey: "AIzaSyCS2n8kOrES09a9gLpFIc2olFQACRMObzM",
    authDomain: "location-dev-254617.firebaseapp.com",
    databaseURL: "https://location-dev-254617.firebaseio.com",
    projectId: "location-dev-254617",
    storageBucket: "location-dev-254617.appspot.com",
    messagingSenderId: "175893697790",
    appId: "1:175893697790:web:2fb71760be596c240f81af"
};

if (hostName === "track.airportexpresslima.com") {
    apiUrl = "https://us-central1-location-254617.cloudfunctions.net/api";
    console.log("prod");
    firebase.initializeApp(productionConfig);
} else {
    apiUrl = "https://us-central1-location-dev-254617.cloudfunctions.net/api";
    console.log("dev");
    firebase.initializeApp(devConfig);
}

let pageLoaded = false;

firebase.firestore()
    .collection("versions")
    .onSnapshot(() => {
        pageLoaded && document.location.reload(true);
        pageLoaded = true;
    });


const firestore = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const server = apiUrl;

export {
    server,
    storage,
    firestore,
    auth,
    apiUrl
};
