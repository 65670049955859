import React from "react";

import spanish from "../res/images/spanish.png";
import english from "../res/images/english.png";
import french from "../res/images/french.png";
import portuguese from "../res/images/portuguese.png";

export const i18n = [
    {
        languageCode: "es",
        languageName: "Español",
        languageFlag: <img alt="español"
                           src={spanish}/>,
        title: "Verifique la ubicación de los buses hacia el aeropuerto",
        subtitle: "¿En qué distrito tomarás el bus?",
        messageTitle: "Nota: ",
        message: "Los buses se muestran 5 minutos antes del inicio de cada servicio.",
        note: "La ubicación de los buses se actualiza a",
        noteBold: "cada minuto."
    },
    {
        languageCode: "en",
        languageName: "English",
        languageFlag: <img alt="english"
                           src={english}/>,
        title: "Check the location of the bus to the airport",
        subtitle: "What district will you get on the bus?",
        messageTitle: "Note: ",
        message: "Buses will appear 5 minutes before the start of service.",
        note: "The location of the buses will be updated",
        noteBold: "every minute."
    },
    {
        languageCode: "fr",
        languageName: "French",
        languageFlag: <img alt="french"
                           src={french}/>,
        title: "Voir la location de notre bus pour l'aéroport",
        subtitle: "Depuis quel district allez-vous prendre le bus?",
        messageTitle: "Info: ",
        message: "Les bus arriveront 5 minutes avant leurs horaires de départ.",
        note: "",
        noteBold: ""
    },
    {
        languageCode: "pt",
        languageName: "Portuguese",
        languageFlag: <img alt="portuguese"
                           src={portuguese}/>,
        title: "Veja a localização do ônibus para o aeroporto",
        subtitle: "Qual bairro você pegará o ônibus?",
        messageTitle: "Nota: ",
        message: "Os ônibus são mostrados 5 minutos antes do início de cada serviço.",
        note: "A localização  dos ônibus são atualizados a",
        noteBold: "cada minuto."
    }
];