import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import {Redirect} from "react-router";
import {Admin, Companies, Map, Users} from "../pages";
import {BaseLayout, CompanyForm, UserForm} from "../components";
import PrivateRoute from "./PrivateRoute";

export default class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route exact
                       path="/admin"
                       render={() => <Admin/>}
                />
                <Route exact
                       path="/"
                       render={() => <Map {...this.props}/>}
                />
                <PrivateRoute exact
                              path="/admin/users">
                    <BaseLayout {...this.props}>
                        <Users/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/admin/companies">
                    <BaseLayout {...this.props}>
                        <Companies/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/admin/users/:userId">
                    <BaseLayout {...this.props}>
                        <UserForm/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/admin/companies/:companyId">
                    <BaseLayout {...this.props}>
                        <CompanyForm/>
                    </BaseLayout>
                </PrivateRoute>
                <Redirect to="/admin"/>
            </Switch>
        )
    }
}