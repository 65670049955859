import React, {Component} from "react";
import {Drawer, Icon, Layout, Menu} from "antd";
import {doSignOut} from "../firebase/auth";
import {Link} from "react-router-dom";

export default class BaseLayout extends Component {

    state = {
        collapsed: true,
    };

    logOut = () => doSignOut();

    toggle = () => this.setState({collapsed: !this.state.collapsed});

    childWithProperties = (props) => React.Children.map(this.props.children, child => React.cloneElement(child, {...props}));

    render() {
        return (
            <Layout id="base-layout">
                <Drawer className="base-sidebar"
                        title="Version 0.3"
                        placement="left"
                        closable={true}
                        visible={!this.state.collapsed}
                        onClose={this.toggle}>
                    <Menu mode="inline"
                          onSelect={this.toggle}>
                        {
                            menuByRole("admin").map(menu =>
                                <Menu.Item key={menu.route}>
                                    <Link to={menu.route}
                                          onClick={() => this.setState({collapsed: true})}
                                          id={menu.id}>
                                        <Icon type={menu.icon}/>
                                        <span>{menu.title}</span>
                                    </Link>
                                </Menu.Item>
                            )
                        }
                        <Menu.Item key="logout"
                                   onClick={() => this.logOut()}>
                            <Icon type="logout"/>
                            <span>Logout</span>
                        </Menu.Item>
                    </Menu>
                </Drawer>
                <Layout className="sub-layout">
                    <Layout.Header className="header">
                        <Icon className="icon-trigger"
                              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                              onClick={this.toggle}/>
                        <span className="header-title">Track Me</span>
                    </Layout.Header>
                    <Layout.Content className="content">
                        {this.childWithProperties(this.props)}
                    </Layout.Content>
                </Layout>
            </Layout>
        )
    }
};

const menu = {
    "companies": {
        id: "companies",
        route: "/admin/companies",
        icon: "reconciliation",
        title: "Companies"
    },
    "users": {
        id: "users",
        route: "/admin/users",
        icon: "user",
        title: "Users"
    }
};

const menuByRole = (roleCode) => {
    if (roleCode === "admin") return [menu.users, menu.companies];
};